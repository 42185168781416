import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Group } from '../models/group';
import { Mongo } from '@core/models/mongo';

@Injectable({
  providedIn: 'root',
})
export class GroupsService {
  private http = inject(HttpClient);

  private readonly BASE_URL = environment.RH_BASE_URL;

  public getGroups(): Observable<Group[]> {
    return this.http.get<Group[]>(`${this.BASE_URL}/mgmt/groups?pagesize=1000`);
  }

  public getById(id: Mongo.ObjectId): Observable<Group> {
    return this.http.get<Group>(`${this.BASE_URL}/mgmt/groups/${id.$oid}`);
  }
}
